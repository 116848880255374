// import picturefill from 'picturefill';
// import 'matchmedia-polyfill';

// WCAG-compliant skip to content links
(function skipToLinks() {
  const skipToContentLink = document.querySelector('.skip-to-content');

  skipToContentLink.addEventListener('focus', function() {
    this.setAttribute('aria-hidden', false);
  });

  skipToContentLink.addEventListener('blur', function() {
    this.setAttribute('aria-hidden', true);
  });
})();

(function wrapLastWord() {
  const el = document.querySelector('.preface__subtitle');
  const text = el.textContent;

  if (text) {
    let splitText = text.split(' ');
    const lastWord = splitText.pop();
    const wrappedLastWord = `<span>${lastWord}</span>`;
    splitText.push(wrappedLastWord);
    const newText = splitText.join(' ');
    el.innerHTML = newText;
  }
})();

// WCAG-compliant navigation toggling
(function navigationToggling() {
  const siteNavigation = document.querySelector('.navigation');
  const navigationToggle = document.querySelector('.navigation-toggle');

  // Create a media query listener
  const smallScreenMQ = window.matchMedia('(max-width: 30em)');

  // Attach a listener and have it fire the collapsibleMenu function
  smallScreenMQ.addListener(collapsibleMenu);

  function collapsibleMenu(sMQ) {
    if (sMQ.matches) {
      navigationToggle.addEventListener('click', toggleNavigation);
      navigationToggle.setAttribute('aria-expanded', 'false');
      navigationToggle.hidden = false;
      siteNavigation.hidden = true;
    } else {
      navigationToggle.removeEventListener('click', toggleNavigation);
      navigationToggle.hidden = true;
      siteNavigation.hidden = false;
    }

    return;
  }

  function toggleNavigation() {
    let expanded = this.getAttribute('aria-expanded') === 'true';
    this.setAttribute('aria-expanded', String(!expanded));
    siteNavigation.hidden = expanded;
  }

  // Call the mediq query listener once to make sure it fires.
  // This should happen by default when attaching a listener, but the spec isn't
  // filled out enough to be sure it does.
  collapsibleMenu(smallScreenMQ);
})();
